export default [
  // {
  //   header: 'Apps & Pages',
  //   resource: 'InitialAbility',
  //   action: 'read',
  // },
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Chat',
    action: 'read',
  },
  {
    title: 'Organization',
    icon: 'DatabaseIcon',
    route: 'organization',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Rules Management',
    icon: 'AlignJustifyIcon',
    route: 'manage-rules',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Matches',
    icon: 'AirplayIcon',
    route: 'matches',
    resource: 'Matches',
    action: 'read',
  },
  {
    title: 'Tournaments',
    icon: 'AwardIcon',
    route: 'tournaments',
    resource: 'Tournament',
    action: 'read',
  },
  {
    title: 'Videos',
    icon: 'VideoIcon',
    route: 'videos',
    resource: 'Chat',
    action: 'read',
  },
  {
    title: 'Chat',
    icon: 'MessageSquareIcon',
    route: 'chats',
    resource: 'Chat',
    action: 'read',
  },
]
