<template>
  <v-select
    v-model="organizationSelected"
    class="m-1"
    :reduce="label => label.code"
    label="label"
    :options="organizationMainOption"
    placeholder="Select Organization"
    :clearable="orgClearable"
    @input="changeOrganization(true)"
  />
</template>

<script>

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  data() {
    return {
      organizationSelected: '',
      organizationMainOption: [],
      orgClearable: false,
      adminExists: false,
      availableRoles: [],
      currentRole: '',
    }
  },
  created() {
    this.getOrganization()
  },
  methods: {
    getOrganization() {
      this.$http.get('/list/getUserOrganization')
        .then(res => {
          if (res.data.success) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const notAdmin = (userData.roles.indexOf('admin') === -1)
            if (!notAdmin) {
              this.orgClearable = true
              this.adminExists = true
              this.$store.dispatch('app/updateUserRole', 'admin')
              this.$store.dispatch('app/updateUserRole', 'admin')
            }
            this.organizationMainOption = res.data.data.organizations
            this.availableRoles = res.data.data.availableRoles
            const organizationSelectedLocal = localStorage.getItem('organizationSelected')
            if (organizationSelectedLocal) {
              this.organizationSelected = parseInt(organizationSelectedLocal, 10)
              this.changeOrganization(false)
            } else if (notAdmin && this.organizationMainOption.length) {
              this.organizationSelected = this.organizationMainOption[0].code
              this.changeOrganization(false)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Organization fetching failed',
                icon: 'BellIcon',
                text: 'Oops! Something Went Wrong',
                variant: 'danger',
              },
            }, {
              timeout: 3000,
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Organization fetching failed',
              icon: 'BellIcon',
              text: 'Oops! Something Went Wrong',
              variant: 'danger',
            },
          }, {
            timeout: 3000,
          })
        })
    },
    changeOrganization(changed) {
      localStorage.setItem('organizationSelected', this.organizationSelected)
      this.$http.post('/get/user/role/abilities', { organization: this.organizationSelected })
        .then(res => {
          if (res.data.success) {
            let abilities
            let orgName
            if (this.organizationSelected === null || Number.isNaN(this.organizationSelected)) {
              abilities = [{
                action: 'read',
                subject: 'InitialAbility',
              },
              {
                action: 'read',
                subject: 'Auth',
              },

              {
                action: 'read',
                subject: 'Chat',
              },
              ]

              if (this.adminExists) {
                this.orgClearable = true
                this.$store.dispatch('app/updateUserRole', 'admin')
                abilities.push({
                  action: 'read',
                  subject: 'Admin',
                })
                orgName = 'Game7'
              }
            } else {
              this.$store.dispatch('app/updateUserRole', this.availableRoles[this.organizationSelected])
              abilities = res.data.data.values
              orgName = this.organizationMainOption.find(x => x.code === this.organizationSelected).label
            }
            this.$store.dispatch('app/updateOrgName', orgName)
            this.$ability.update(abilities)
            const localItems = JSON.parse(localStorage.getItem('userData'))
            localItems.ability = abilities
            // console.log(abilities)
            localStorage.setItem('userData', JSON.stringify(localItems))
            // Redirect to home page
            if (this.$route.name !== 'home' && changed) this.$router.push({ name: 'home' })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Organization fetching failed',
              icon: 'BellIcon',
              text: 'Oops! Something Went Wrong',
              variant: 'danger',
            },
          }, {
            timeout: 3000,
          })
        })
    },
  },
}
</script>
