<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="org-name text-truncate d-xl-none">
      {{ orgName }}
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status text-capitalize">{{ getUserRole }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-icon-three-dots-vertical />
        </template>
        <b-dropdown-item
          :to="{ name: 'wallet' }"
          link-class="d-flex align-items-center"
        >
          <b-icon-cash-stack
            class="mr-50"
          />
          <span>Wallet</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'transactions' }"
          link-class="d-flex align-items-center"
        >

          <b-icon-card-list
            class="mr-50"
          />
          <span>Transactions</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'settings' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BIconThreeDotsVertical, BIconCashStack, BIconCardList,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters } from 'vuex'
import NotificationDropdown from './NotificationDropdown.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BIconCashStack,
    BIconCardList,
    BIconThreeDotsVertical,
    // Navbar Components
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: 'app/getUserRole',
      orgName: 'app/getOrgName',
    }),
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      organizationSelected: '',
      organizationMainOption: [],
      role: '',
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      useJwt.logout().then(() => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        // Remove userData from localStorage
        localStorage.removeItem('userData')

        localStorage.removeItem('organizationSelected')
        // Reset ability
        this.$ability.update(initialAbility)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sign Out',
            icon: 'BellIcon',
            text: 'You have been signed out successfully.',
            variant: 'success',
          },
        }, {
          timeout: 3000,
        })
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
